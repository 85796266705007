<template>
  <div />
</template>

<script>
import currency from "currency.js"

export default {
    name: "Utils",
    components: {},
    data: () => ({
        snackbar: {
            isConfirmMode: false,
            activeFlag: 0,
            errorFlag: false,
            permanentFlag: false,
            text: '',
            phone: '',
            whatsappMsg: '',
            confirm: null,
        },
        contactsAPISuport: (('contacts' in navigator && 'ContactsManager' in window) || 'mozContacts' in navigator),
        contactInfo: {
            name: '',
            tel: '',
        },
        flagContactSelected: 0,
    }),
    computed: {},
    watch: {},
    mounted() {},
    methods: {
        formatCurrency: function(valor) {
            let real = value => currency(value, { separator: ".", decimal: "," })
            return real(valor).format()
        },
        formatPhone: function(phone) {
            if (phone.length == 13) {
                return (
                    "(" +
          phone.substr(2, 2) +
          ") " +
          phone.substr(4, 5) +
          "-" +
          phone.substr(9, 4)
                )
            } else {
                return (
                    "(" +
          phone.substr(2, 2) +
          ") " +
          phone.substr(4, 5) +
          "-" +
          phone.substr(8, 4)
                )
            }
        },
        unformatPhone: function(phone) {
            if(!phone) return ''
            const onlyDigits = phone.replace(/\D/g, "")
            if (onlyDigits.length == 11)
                return "55" + onlyDigits
            else return onlyDigits
        },
        capitalized: function(str) {
            if (typeof str !== "string") return ""
            return str.charAt(0).toUpperCase() + str.slice(1)
        },
        cpfIsValid(strCPF) {
            strCPF = strCPF.replace(/\D/g,"")
            let Soma
            let Resto
            let i
            Soma = 0
            if (strCPF == "00000000000") return false

            for (i = 1; i <= 9; i++)
                Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
            Resto = (Soma * 10) % 11

            if (Resto == 10 || Resto == 11) Resto = 0
            if (Resto != parseInt(strCPF.substring(9, 10))) return false

            Soma = 0
            for (i = 1; i <= 10; i++)
                Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
            Resto = (Soma * 10) % 11

            if (Resto == 10 || Resto == 11) Resto = 0
            if (Resto != parseInt(strCPF.substring(10, 11))) return false
            return true
        },
        notify: function(text = '', error = false, permanent = true, phone = '', whatsappMsg = '') {
            this.snackbar.text = text
            this.snackbar.phone = phone
            this.snackbar.errorFlag = error
            this.snackbar.permanentFlag = permanent
            this.snackbar.isConfirmMode = false
            this.snackbar.color = '#fff'
            this.snackbar.activeFlag++
            this.snackbar.whatsappMsg = whatsappMsg
        },
        confirm: async function(text = '') {
            this.snackbar.text = text
            this.snackbar.phone = ''
            this.snackbar.errorFlag = false
            this.snackbar.permanentFlag = true
            this.snackbar.isConfirmMode = true
            this.snackbar.confirm = null
            this.snackbar.activeFlag++
            await this.waitUserConfirmation()
            return this.snackbar.confirm
        },
        waitUserConfirmation: function() {
            return new Promise((resolve) => {
                if (this.snackbar.confirm == null)
                    setTimeout(async () => {
                        await this.waitUserConfirmation()
                        resolve()
                    }, 100)
                else resolve()
            })
        },
        clearPhone: function(phone) {
            phone = phone.replace(/\D/g, '')
            phone = phone.slice(-11)
            phone = phone.replace(/^0+/, '')
            return phone
        },
        abreContatos: function() {
            let api = navigator.contacts || navigator.mozContacts || window.ContactsManager

            let foundResults = null
            if (api && !!api.select) // new Chrome API
                foundResults = api.select(['name', 'tel'], { multiple: false })
            else if (api && !!api.find) // old Firefox OS API
                foundResults = api.find({
                    sortBy: 'familyName',
                    sortOrder: 'ascending'
                })
            else
                return this.notify('Seu navegador não suporte a API contatos.')

            foundResults.then((contacts) => {
                // this.notify('Encontrado ' + contacts.length + ' contatos.')
                if (contacts.length) {
                    this.contactInfo.name = contacts[0].name[0]
                    //this.contactInfo.mail = contacts[0].email[0]

                    if (contacts[0].tel.length > 1) {
                        this.notify('Parece que o contato selecionado possui mais de 1 número de telefone. Somente o primeiro será importado!')
                        this.contactInfo.tel = this.clearPhone(contacts[0].tel[0])
                    } else {
                        this.contactInfo.tel = this.clearPhone(contacts[0].tel[0])
                    }

                    this.flagContactSelected++

                    // let nameInput = document.querySelector('input[name="name"]')
                    // let phoneInput = document.querySelector('input[name="phone"]')

                    // if(nameInput)
                    //     nameInput.value = contacts[0].name
                    // if(phoneInput)
                    //     phoneInput.value = contacts[0].phone

                    // this.notify(
                    //     'Primeiro contato: ' +
                    //         contacts[0].name +
                    //         ' (' +
                    //         contacts[0].email +
                    //         ')'
                    // )
                }
            }).catch((err) => {
                this.notify('Erro ao buscar contatos: ' + err.name)
            })
        },
        inicializeReCaptcha: function() {
            let meta = document.createElement('meta')
            meta.name = 'grecaptcha-key'
            meta.content = process.env.VUE_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY
            meta.setAttribute('id', 'grecaptcha-metatag')
            document.head.appendChild(meta)

            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('id', 'grecaptcha-script')
            recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?ReCaptchaCallbackV3&render=${process.env.VUE_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}`)
            document.head.appendChild(recaptchaScript)
        },
        destroyReCaptcha: function() {
            document.querySelector('body > div > .grecaptcha-badge')
                .parentNode.remove()
            document.querySelector('#grecaptcha-script').remove()
            document.querySelector('#grecaptcha-metatag').remove()
        },
        stringDuracao: function (minutes){
            let roudedMinutes = Math.round(minutes)
            let h = Math.floor( roudedMinutes / 60)
            let m = roudedMinutes % 60
            h = h < 10 ? h : h
            m = m < 10 ? '0' + m : m
            return (h > 0 ? h+'h' : '') + (m > 0 ? m+'min' : '')
        },
    }
}
</script>

<style lang="sass" scoped>
</style>